exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-js": () => import("./../../../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-posts-prismic-post-uid-js": () => import("./../../../src/pages/posts/{PrismicPost.uid}.js" /* webpackChunkName: "component---src-pages-posts-prismic-post-uid-js" */),
  "component---src-pages-prismic-standard-page-uid-js": () => import("./../../../src/pages/{PrismicStandardPage.uid}.js" /* webpackChunkName: "component---src-pages-prismic-standard-page-uid-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

